import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/Dashboard.vue")
                },
                {
                    path: "chat",
                    name: "chat",
                    component: () => import("@/view/pages/chat/Chat.vue")

                },
                {
                    path: "academic-class",
                    name: "academic-class",
                    component: () => import("@/view/pages/academic-class/Index.vue")

                },
                {
                    path: "attendance",
                    name: "class-attendance",
                    component: () => import("@/view/pages/attendance/Attendance.vue")

                },
                {
                    path: "notification",
                    name: "notification",
                    component: () => import("@/view/pages/notifications/Notification.vue")

                },
                {
                    path: "leave",
                    name: "leave",
                    meta: {

                        title: 'Notification-topic', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/leave/leave/Index.vue")
                },
                {
                    path: "courses",
                    name: "students-courses",
                    component: () => import("@/view/pages/courses/Courses.vue")

                }, , {
                    path: "timetable",
                    name: "students-routine",
                    component: () => import("@/view/pages/time-table/TimeTable.vue")

                },
                {
                    path: "exams",
                    name: "students-exam",
                    component: () => import("@/view/pages/exams/Exam.vue")

                },
                {
                    path: "/:exam_id/:grade_id/results",
                    name: "students-results",
                    component: () => import("@/view/pages/results/Results.vue")

                },
                {
                    path: "unauthorized",
                    name: "unauthorized",
                    component: () => import("@/view/pages/error/unauthorized.vue")
                },
                {
                    path: "user-profile",
                    name: "profile",
                    component: () => import("@/view/pages/profile/Index.vue")

                }, {

                    path: "finance",
                    name: "finance",
                    component: () => import("@/view/pages/finance/Dashboard.vue")
                },
                {
                    path: "/:lesson_plan_id/courses-summary",
                    name: "courses-summary",
                    component: () => import("@/view/pages/lesson-planner/Summary.vue")

                },
                {
                    path: "lesson-planer-summary/:lesson_plan_id",
                    name: "lesson-planer-summary",
                    component: () => import("@/view/pages/courses/summary.vue")
                },
                {
                    path: "online-class",
                    name: "online-class",
                    component: () => import("@/view/pages/online-class/Index.vue")

                },
                {
                    path: "teachers",
                    name: "teachers",
                    component: () => import("@/view/pages/teachers/Index.vue")

                },
                {
                    path: "assignment",
                    name: "assignment",
                    component: () => import("@/view/pages/assignment/Index.vue")

                },
                {
                    path: "assignment-questions",
                    name: "assignment=questions",
                    component: () => import("@/view/pages/assignment/question/Index.vue")

                },
                {
                    path: "assignment/:assignment_id",
                    name: "assignment-edit",
                    component: () => import("@/view/pages/assignment/NewAssignment.vue")

                },
                {
                    path: "assignment/:assignment_id/assignment-summary",
                    name: "assignment-summary",
                    component: () => import("@/view/pages/assignment/AssignmentDetail.vue")

                },
                {
                    path: "assignment/:assignment_id/submit",
                    name: "assignment-submit",
                    component: () => import("@/view/pages/assignment/SubmitAssignment.vue")

                },
                {
                    path: "quiz/:quiz_id/prepare",
                    name: "quiz-prepare",
                    component: () => import("@/view/pages/quiz/PrepareQuiz.vue")

                },
                {
                    path: "quiz/:quiz_id/start",
                    name: "quiz-start",
                    component: () => import("@/view/pages/quiz/Quiz.vue")

                },
                {
                    path: "support",
                    name: "support",
                    component: () => import("@/view/pages/support/Index.vue")
                },
                {
                    path: "digital-id",
                    name: "digital-id",
                    component: () => import("@/view/pages/user/DigitalId.vue")
                },
                {
                    path: "invoice",
                    name: "invoice",
                    component: () => import("@/view/pages/invoice/Index.vue")
                },
                {
                    path: "invoice/:id/detail",
                    name: "invoice-detail",
                    component: () => import("@/view/pages/invoice/InvoiceDetail.vue")
                },
                {
                    path: "my-activity",
                    name: "my-activities",
                    component: () => import("@/view/pages/timeline/Index.vue")
                },
                {
                    path: "parents-meet",
                    name: "parents-meet",
                    component: () => import("@/view/pages/parents-meet/Index.vue")
                },
                {
                    path: "resource",
                    name: "resource",
                    component: () => import("@/view/pages/resource/Index.vue")
                },
                {
                    path: "/library",
                    name: "library-main",
                    component: () => import("@/view/pages/library/Base.vue"),
                    children: [
                        {
                            path: "book",
                            name: "books",
                            component: () => import("@/view/pages/library/page/Index.vue")
                        },

                        {

                            path: "resource/:id/attachments",
                            name: "library-resource-attachment-list",
                            component: () => import("@/view/pages/library/page/resource-attachments/Index.vue")
                        }, {
                            path: "resource/:resource/attachment/:attachment/view",
                            name: "library-resource-attachment-view",
                            component: () => import("@/view/pages/library/page/resource-attachments/View.vue")
                        }, {
                            path: "book/:id/detail",
                            name: "book-detail",
                            component: () => import("@/view/pages/library/book/Detail.vue")
                        }, {
                            path: "my-wishlist",
                            name: "my-wishlist",
                            component: () => import("@/view/pages/library/wishlist/Index.vue")
                        },
                        {
                            path: "my-issue",
                            name: "my-issue",
                            component: () => import("@/view/pages/library/issue/Index.vue")
                        },
                    ]
                },
                {
                    path: "pending-gate-passes",
                    name: "pending-gate-passes",
                    meta: {

                        title: 'Pending gate pass',
                        icon:'fas fa-spinner',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/gate-pass/Pending.vue")
                },

                {
                    path: "gate-passes",
                    name: "gate-passes",
                    meta: {

                        title: 'Gate pass',
                        icon:'fas fa-file-pdf',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/gate-pass/Index.vue")
                },
                {
                    path: "general-attendance",
                    name: "general-attendance",
                    meta: {

                        title: 'Check in/out',
                        icon:'fas fa-school',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/general-attendance/Index.vue")
                },
                {
                    path: "general-request/:type",
                    name: "general-request",
                    meta: {

                        title: 'General request',
                        icon:'fas fa-school',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/general-request/Index.vue")
                },
                {
                    path: "user-group",
                    name: "user-group",
                    meta: {

                        title: 'User group',
                        icon:'fas fa-school',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/group/Index.vue")
                },
                {
                    path: "user-group/:userGroupId/member",
                    name: "user-group-member",
                    meta: {

                        title: 'User group member',
                        icon:'fas fa-school',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/group/member/Index.vue")
                },
            ]
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "/error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue")
                }
                // {
                //     path: "error-2",
                //     name: "error-2",
                //     component: () => import("@/view/pages/error/Error-2.vue")
                // },
                // {
                //     path: "error-3",
                //     name: "error-3",
                //     component: () => import("@/view/pages/error/Error-3.vue")
                // },
                // {
                //     path: "error-4",
                //     name: "error-4",
                //     component: () => import("@/view/pages/error/Error-4.vue")
                // },
                // {
                //     path: "error-5",
                //     name: "error-5",
                //     component: () => import("@/view/pages/error/Error-5.vue")
                // },
                // {
                //     path: "error-6",
                //     name: "error-6",
                //     component: () => import("@/view/pages/error/Error-6.vue")
                // }
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "login",
                    component: () => import("@/view/pages/auth/Login")
                },
                // {
                //     name: "register",
                //     path: "register",
                //     component: () => import("@/view/pages/auth/Register")
                // },
                {
                    name: "verification",
                    path: "verification",
                    component: () => import("@/view/pages/auth/Verification")
                },
                {
                    name: "forgot-password",
                    path: "forgot-password",
                    component: () => import("@/view/pages/auth/ForgotPassword")
                },
                {
                    name: "reset-password",
                    path: "reset-password",
                    component: () => import("@/view/pages/auth/ResetPassword")
                }
            ]
        },
        {
            path: "*",
            redirect: "404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        },

    ]
});
