const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
    let t = window.localStorage.getItem(ID_TOKEN_KEY);
    if (t == undefined || t == "undefined" || t == null || t == '')
      window.localStorage.setItem(ID_TOKEN_KEY, token);
  
  };

export const destroyToken = () => {
    if(window.localStorage.getItem(ID_TOKEN_KEY)){
        window.localStorage.removeItem(ID_TOKEN_KEY);
        window.location.reload()
    }
};

export default { getToken, saveToken, destroyToken };
