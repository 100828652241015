const ClubPresident = {

    state: {
        is_president: false
    },
    getters: {
        getIsPresident(state) {
            return state.is_president;
        }
    },
    actions: {
        GET_IS_PRESIDENT: (context, payload) => {
            context.commit('SET_IS_PRESIDENT', payload)
        }
    },
    mutations: {
        SET_IS_PRESIDENT: (state, payload) => {
            state.is_president = payload;
        }
    },

}
export default ClubPresident;
