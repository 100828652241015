<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
//@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "~vue-snotify/styles/material";

:root {
  --body-bg-color: #f3f6f9;
  --primary-color:#009387;
  --secondary-color: #007190;
  --primary-text-color:#000;
  --secondary-text-color: #ffffff;

  //Login
  --login-bg-color:#e4edf2;
  --login-primary-color:#009387;
  --login-secondary-color:#007190;

  //buttons
  --button-bg-color:#007190;
  --button-primary-color:#009387;
  --button-secondary-color:#007190;
  --button-primary-text-color:#00458f;
  --button-secondary-text-color:#f2883a;


  //Sidebar
  --sidebar-primary-color:#009387;
  --sidebar-secondary-color: #007190;
  --sidebar-primary-text-color:#fff;
  --sidebar-secondary-text-color: #ffffff;
  --sidebar-bg-color: #009387;
  --sidebar-active-menu-bg-color: #ffffff;
  --sidebar-active-menu-text-color: #ffffff;
  --sidebar-menu-text-color: #ffffff;

  //Sub Header
  --header-primary-color:#009387;
  --header-secondary-color: #007190;
  --header-primary-text-color:#ffffff;
  --header-secondary-text-color: #ffffff;

  --sub-header-bg-color: blue;
  --sub-header-text-color: blue;
  --color-white: #fff;
  --color-dark: #212121;
}


// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/


.v-stepper__step .v-expansion-panel-content__wrap {
  padding:0 !important;
}
img {
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .v-aplication .d-flex {
    flex-wrap: wrap !important;
  }
  img {
    object-fit: contain;
  }
  body {
    overflow-x: hidden !important;
  }
}

@media screen and (max-width: 767px) {
  .icon-grid-info > *  {
    flex-basis: 50%;
    max-width: 50%;
    overflow: hidden;
  }
  .v-application .icon-grid-info .display-3  {
    font-size: 30px !important;
  }
}
</style>

<script>
import {OVERRIDE_LAYOUT_CONFIG} from "@/core/services/store/config.module";

export default {
  name: "Yelko",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
