export default {
    state: {
        quiz_id: null,
        quiz: {},
        quiz_attempt: {}
    },
    getters: {
        getQuizId(state) {
            return state.quiz_id;
        },
        getQuiz(state) {
            return state.quiz;
        },
        getQuizAttempt(state) {
            return state.quiz_attempt;
        }
    },
    actions: {
        setQuizId(context, payload) {
            context.commit('setQuizId', payload)
        },
        setQuiz(context, payload) {
            context.commit('setQuiz', payload)
        },
        setQuizAttempt(context, payload) {
            context.commit('setQuizAttempt', payload)
        },


    },
    mutations: {
        setQuizId(state, payload) {
            state.quiz_id = payload
        },
        setQuiz(state, payload) {
            state.quiz = payload
        },
        setQuizAttempt(state, payload) {
            state.quiz_attempt = payload
        },
    },
}