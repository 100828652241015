const setting = {

    state: {
        organisation_setting: {}
    },
    getters: {
        getOrganisationSetting(state) {
            return state.organisation_setting
        }
    },
    mutations: {
        SET_ORGANISATION_SETTING: (state, payload) => {
            state.organisation_setting=payload;
        }
    },
}
export default setting;