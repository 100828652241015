import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import quiz from "@/core/services/store/quiz";
import setting  from "@/core/services/store/setting"
import club_president  from "@/core/services/store/club_president"
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    quiz,
    setting,
    club_president
  }
});
